.App {
  background-color: black;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 5vh;
}
th {
  text-align: left;
}

.App-link {
  color: #61dafb;
}

.dark-mode {
  background-color: black;
  color: white;
}

.white-content {
  background: #c4c3c0;
}

.form-control {
  background: #e8dfdf;
}


textarea.form-control {

  background: #afada8;
}